<template>
<div>
<section class="hero has-background">
<img class="hero-background" src="../../assets/brand-header.jpg">
<div class="hero-body">
<div class="container" style="margin-top: 5vh;">
<img src="../../assets/brand_logo.svg">
</div>
</div>
</section>
<div class="container" style="margin-top: -30px;">
<div class="box bunya-box">
<div class="columns">

<div class="column is-3 with-vertical-line cell-padding">
<div class="media is-no-border-media">
    <b-icon class="media-left" icon="cash" size="is-medium"></b-icon>
    <div class="media-content">
        <span class="title">$1.509,99 </span><br>
        <small class="subtitle">to be paid</small>
    </div>
</div>
</div>

<div class="column is-3 with-vertical-line cell-padding">
<div class="media is-no-border-media">
    <b-icon class="media-left" icon="bank-outline" size="is-medium"></b-icon>
    <div class="media-content">
        <span class="title">to be paid</span><br>
        <small class="subtitle">12 Aug 2019</small>
    </div>
</div>
</div>

<div class="column is-3 cell-padding">
<div class="media is-no-border-media">
    <b-icon class="media-left" icon="bank-outline" size="is-medium"></b-icon>
    <div class="media-content">
        <span class="title">invoice sent </span><br>
        <small class="subtitle">12 Aug 2019</small>
    </div>
</div>
</div>

<div class="column is-3 cell-padding" style="text-align: right;">
<button class="button bunya-button">Payment</button>
</div>

</div>
</div>

<div class="box bunya-box">
<div class="columns">
<div class="column is-4 with-vertical-line cell-padding">
<div class="media is-no-border-media">
    <b-icon class="media-left" icon="cash" size="is-medium"></b-icon>
    <div class="media-content">
        <h3><b>Bill To</b></h3>
        <small>Digistorm<br>
        3/4 James St, Burleigh Heads,<br>
        QLD 4220
        </small>
    </div>
</div>
</div>
<div class="column is-4 with-vertical-line cell-padding">
<div class="media is-no-border-media">
    <b-icon class="media-left" icon="bank-outline" size="is-medium"></b-icon>
    <div class="media-content">
        <h3><b>Received From</b></h3>
        <small>Full Steam Ahead<br>
        12 Kelly St, Hawthorne<br>
        QLD 4171
        </small>
        <br>
        <small><b>ABN:</b> 70 609 940 969</small>
    </div>
</div>

</div>
<div class="column is-4 cell-padding">
<img src="../../assets/brand_logo.svg"><br>
<p>
<a>www.fullsteamhead.com</a><br>
<b>ABN:</b> 70 609 940 969
</p>
</div>
</div>
</div>

<div class="box bunya-box">
<div class="level">
<div class="level-left">
    <div class="level-item">
        <b-icon class="media-left" icon="bank-outline"></b-icon> <b>Invoice #IN-00165</b>
    </div>
    </div>
    <div class="level-right">
        <div class="level-item">
           <b-button class="payment-rounded-button" rounded icon-left="download">Download PDF</b-button>
        </div>
    </div>
</div>
<hr>
<!--Table Start-->
<table class="table is-fullwidth">
        <thead>
          <tr>
            <th> # </th>
            <th> Product / Service </th>
            <th> Description </th>
            <th> Quantity </th>
            <th> Unit Price </th>
            <th> Tax Rate </th>
            <th> Total </th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Total</th>
            <th>$1509.99</th>
          </tr>
        </tfoot>
        <tbody>
          <tr>
            <th>1</th>
            <td>Design work</td>
            <td>Design for prints</td>
            <td>6</td>
            <td>$40.95</td>
            <td>10% GST</td>
            <td>$273</td>
          </tr>
          <tr>
            <th>2</th>
            <td>A3 Stand</td>
            <td>Large holder for posters</td>
            <td>2</td>
            <td>$292.38</td>
            <td>10% GST</td>
            <td>$649.74</td>
          </tr>
          <tr>
            <th>3</th>
            <td>A3 Poster</td>
            <td></td>
            <td>3</td>
            <td>$30.74</td>
            <td>10% GST</td>
            <td>$102.45</td>
          </tr>
          <tr>
            <th>4</th>
            <td>DL Leaflet</td>
            <td>Smaller product leaflets</td>
            <td>100</td>
            <td>$413.10</td>
            <td>10% GST</td>
            <td>$459.00</td>
          </tr>
          <tr>
            <th>5</th>
            <td>A4 Poster</td>
            <td></td>
            <td>1</td>
            <td>$23.22</td>
            <td>10% GST</td>
            <td>$25.80</td>
          </tr>
          <tr>
            <th></th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Sub Total</td>
            <td>$1358.99</td>
          </tr>
          <tr>
            <th></th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Total Tax</td>
            <td>$150.99</td>
          </tr>
        </tbody>
      </table>
<div class="has-gray-area">
<div class="level">
<div class="level-left">
    <div class="level-item">
    <div class="media is-no-border-media">
    <b-icon class="media-left" icon="receipt" size="is-medium"></b-icon>
    <div class="media-content">
        <h3><b>Thanks Client Name for your service !</b></h3>
        <small>- Sherri</small>
    </div>
    </div>
    </div>
    </div>
    <div class="level-right">
        <div class="level-item">
           <button class="button bunya-button">Payment</button>
        </div>
    </div>
</div>
</div>
</div>
</div>
<br><br>
<div class="footer">
<div class="container">
<div class="level">
    <div class="level-left">
        <div class="level-item">
            &copy; 2020 Full Steam Ahead
        </div>
    </div>
    <div class="level-left">
        <div class="level-item">
           <span>Powered By <img src="../../assets/bunya-logo.png" width="24" height="24"></span>
        </div>
    </div>
</div>
</div>
</div>
</div>
</template>

<script>
export default {
  name: 'Payment',
  components: { }
}
</script>

<style scoped>
.cell-padding {
  padding-left: 2.5vw;
}
.with-vertical-line {
  border-right: 1px #ccc solid;
}
.title{
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 600;
}
.subtitle {
  text-transform: uppercase;
  font-size: 1em;
}
</style>
